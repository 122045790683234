import React from "react"
import { graphql } from "gatsby"
import classnames from "classnames"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"
import { baseMarkdownType } from "../types/types"

const PrivacyPage = ({ data }) => {
  const { 
    html,
    frontmatter: {
      title,
      description,
      subTitle
    }
  } = data.markdownRemark
  return (
    <Layout>
      <SEO title={title} description={description} />
      <Container className={styles.container}>
        <div className={styles.intro}>
          <h1>{title}</h1>
          <h4>{subTitle}</h4>
        </div>
        <div className={classnames(styles.content, "html")} dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query privacyPageQuery {
    markdownRemark(frontmatter: { path: { eq: "/privacy-policy" } }) {
      html
      frontmatter {
        title
        description
        subTitle
      }
    }
  }
`

PrivacyPage.propTypes = {
  ...baseMarkdownType
}

export default PrivacyPage
